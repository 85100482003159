interface Props {
  className?: string;
}

export const CheckboxIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="5" fill="black" />
      <g clip-path="url(#clip0_11_411)">
        <path
          d="M18.6747 7.28821C18.2415 6.85448 17.5381 6.85475 17.1044 7.28821L10.037 14.3559L6.89587 11.2148C6.46213 10.781 5.75904 10.781 5.3253 11.2148C4.89157 11.6485 4.89157 12.3516 5.3253 12.7853L9.25157 16.7116C9.46831 16.9283 9.7525 17.037 10.0367 17.037C10.3209 17.037 10.6054 16.9286 10.8221 16.7116L18.6747 8.85875C19.1084 8.42531 19.1084 7.72193 18.6747 7.28821Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_411">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
