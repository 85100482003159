import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      cz: {
        translation: {
          "Opening hours": "Otevírací doba",
          "Pick-up point not available": "Výdejní místo není k dispozici",
          "Pick-up point available": "Výdejní místo k dispozici",
          Monday: "Pondělí",
          Tuesday: "Úterý",
          Wednesday: "Středa",
          Thursday: "Čtvrtek",
          Friday: "Pátek",
          Saturday: "Sobota",
          Sunday: "Neděle",
          Filters: "Filtry",
          Company: "Společnost",
          "Deselect all": "Odznačit vše",
          "Select all": "Vybrat vše",
          Apply: "Použít",
          "Cancel all": "Zrušit vše",
          "Wheelchair access": "Přístup pro vozíčkáře",
          "Possibility to pay by card": "Možnost platby kartou",
          "Not possible to pay by card at this pick-up point":
            "Není možné platit kartou na tomto výdejním místě",
          "Open Saturday": "Otevřeno v sobotu",
          "Open Sunday": "Otevřeno v neděli",
          "Back to point list": "Zpět na seznam bodů",
          "Pick up point selection": "Výběr výdejního místa",
          "Search for a pick up point": "Vyhledat výdejní místo",
          "Choose this pick up point": "Vyberte toto výdejní místo",
          "Select an available pick-up point": "Vyberte dostupné výdejní místo",
          "There was an error! Please, try again later!":
            "Nastala chyba! Prosím, zkuste to znovu později!",
          "Load more results...": "Načíst další výsledky...",
          "Please allow access to your location.":
            "Povolte přístup k vaší poloze.",
        },
      },
      en: {
        translation: {
          "Opening hours": "Opening hours",
          "Pick-up point not available": "Pick-up point not available",
          "Pick-up point available": "Pick-up point available",
          Monday: "Monday",
          Tuesday: "Tuesday",
          Wednesday: "Wednesday",
          Thursday: "Thursday",
          Friday: "Friday",
          Saturday: "Saturday",
          Sunday: "Sunday",
          Company: "Company",
          "Select all": "Select all",
          "Deselect all": "Deselect all",
          Apply: "Apply",
          Filters: "Filters",
          "Cancel all": "Cancel all",
          "Wheelchair access": "Wheelchair access",
          "Possibility to pay by card": "Possibility to pay by card",
          "Not possible to pay by card at this pick-up point":
            "Not possible to pay by card at this pick-up point",
          "Open Saturday": "Open Saturday",
          "Open Sunday": "Open Sunday",
          "Back to point list": "Back to point list",
          "Pick up point selection": "Pick up point selection",
          "Search for a pick up point": "Search pickup points near:",
          "Choose this pick up point": "Choose this pick-up point",
          Back: "Back",
          "Select an available pick-up point":
            "Select an available pick-up point",
          "There was an error! Please, try again later!":
            "There was an error! Please, try again later!",
          "Load more results...": "Load more results...",
          "Please allow access to your location.":
            "Please allow access to your location.",
        },
      },
      ro: {
        translation: {
          "Opening hours": "Interval orar",
          "Pick-up point not available": "Punct de colectare indisponibil.",
          "Pick-up point available": "Punct de livrare disponibil",
          Monday: "Luni",
          Tuesday: "Marți",
          Wednesday: "Miercuri",
          Thursday: "Joi",
          Friday: "Vineri",
          Saturday: "Sâmbătă",
          Sunday: "Duminică",
          Filters: "Filtre",
          Company: "Companie",
          "Deselect all": "Deselectează tot",
          "Select all": "Selectează tot",
          Apply: "Aplică",
          "Cancel all": "Anulează tot",
          "Wheelchair access": "Acces pentru persoane cu dizabilități",
          "Possibility to pay by card":
            "Posibilă plata cu cardul la acest punct de colectare",
          "Not possible to pay by card at this pick-up point":
            "Nu este posibilă plata cu cardul",
          "Open Saturday": "Deschis sâmbătă",
          "Open Sunday": "Deschis duminică",
          "Back to point list": "Înapoi la listă",
          "Pick up point selection": "Selectați punctul de livrare",
          "Search for a pick up point": "Căutare după adresă",
          "Choose this pick up point": "Selectați acest punct",
          "Select an available pick-up point":
            "Selectați un punct de livrare disponibil",
          "There was an error! Please, try again later!":
            "Ne pare rău, a intervenit o eroare! Încercați mai târziu!",
          "Load more results...": "Mai multe rezultate...",
          "Please allow access to your location.":
            "Permiteți accesul la locația dumneavoastră!",
        },
      },
      hu: {
        translation: {
          "Opening hours": "Nyitvatartási idő",
          "Pick-up point not available": "Az átvételi pont nem elérhető",
          "Pick-up point available": "Átvételi pont elérhető",
          Monday: "Hétfő",
          Tuesday: "Kedd",
          Wednesday: "Szerda",
          Thursday: "Csütörtök",
          Friday: "Péntek",
          Saturday: "Szombat",
          Sunday: "Vasárnap",
          "Wheelchair access": "Akkadálymentes hozzáférés",
          Filters: "Szűrők",
          Company: "Cég",
          "Deselect all": "Összes kiválasztásának megszüntetése",
          "Select all": "Összes kiválasztása",
          Apply: "Alkalmaz",
          "Cancel all": "Mindent megszüntet",
          "Possibility to pay by card": "Lehetőség kártyás fizetésre",
          "Not possible to pay by card at this pick-up point":
            "Ezen az átvételi ponton nem lehet kártyával fizetni",
          "Open Saturday": "Szombaton nyitva",
          "Open Sunday": "Nyitva vasárnap",
          "Back to point list": "Vissza a pontlistához",
          "Pick up point selection": "Vedd fel a pont kiválasztását",
          "Search for a pick up point": "Keressen egy átvételi pontot",
          "Choose this pick up point": "Válassza ezt az átvételi pontot",
          "Select an available pick-up point":
            "Válasszon egy elérhető átvételi pontot",
          "There was an error! Please, try again later!":
            "Hiba történt! Kérlek, próbáld újra később!",
          "Load more results...": "További eredmények betöltése...",
          "Please allow access to your location.":
            "Kérjük, engedélyezze a hozzáférést a tartózkodási helyéhez.",
        },
      },
      bg: {
        translation: {
          "Opening hours": "Работно време",
          "Pick-up point not available": "Не е наличен пункт за получаване",
          "Pick-up point available": "Наличен пункт за вземане",
          Monday: "понеделник",
          Tuesday: "вторник",
          Wednesday: "сряда",
          Thursday: "четвъртък",
          Friday: "петък",
          Saturday: "Събота",
          Sunday: "неделя",
          "Wheelchair access": "Достъп за инвалидни колички",
          Filters: "Филтри",
          Company: "Компания",
          "Deselect all": "Премахни всички",
          "Select all": "Избери всички",
          Apply: "Приложи",
          "Cancel all": "Отмени всички",
          "Possibility to pay by card": "Възможност за плащане с карта",
          "Not possible to pay by card at this pick-up point":
            "Не е възможно плащане с карта на това място за получаване",
          "Open Saturday": "Отворено събота",
          "Open Sunday": "Отворено неделя",
          "Back to point list": "Назад към списъка с точки",
          "Pick up point selection": "Избор на точка за вдигане",
          "Search for a pick up point": "Потърсете точка за вземане",
          "Choose this pick up point": "Изберете тази точка за вземане",
          "Select an available pick-up point":
            "Изберете налична точка за вземане",
          "There was an error! Please, try again later!":
            "Имаше грешка! Моля, опитайте отново по-късно!",
          "Load more results...": "Зареди още резултати...",
          "Please allow access to your location.":
            "Моля, разрешете достъп до вашето местоположение.",
        },
      },
      sk: {
        translation: {
          "Opening hours": "Otváracia doba",
          "Pick-up point not available": "Výdajný bod nie je k dispozícii",
          "Pick-up point available": "Výdajný bod k dispozícii",
          Monday: "Pondelok",
          Tuesday: "Utorok",
          Wednesday: "Streda",
          Thursday: "Štvrtok",
          Friday: "Piatok",
          Saturday: "Sobota",
          Sunday: "Nedeľa",
          "Wheelchair access": "Prístup pre vozíčkárov",
          Filters: "Filtre",
          Company: "Spoločnosť",
          "Deselect all": "Odznačiť všetko",
          "Select all": "Vybrať všetko",
          Apply: "Použiť",
          "Cancel all": "Zrušiť všetko",
          "Possibility to pay by card": "Možnosť platiť kartou",
          "Not possible to pay by card at this pick-up point":
            "Nie je možné platiť kartou na tomto výdajnom mieste",
          "Open Saturday": "Otvorené v sobotu",
          "Open Sunday": "Otvorené v nedeľu",
          "Back to point list": "Späť na zoznam bodov",
          "Pick up point selection": "Výber výdajného miesta",
          "Search for a pick up point": "Vyhľadať výdajné miesto",
          "Choose this pick up point": "Vyberte toto výdajné miesto",
          "Select an available pick-up point":
            "Vyberte dostupné výdajné miesto",
          "There was an error! Please, try again later!":
            "Nastala chyba! Prosím, skúste to znova neskôr!",
          "Load more results...": "Načítať ďalšie výsledky...",
          "Please allow access to your location.":
            "Povolte prístup k vašej polohe.",
        },
      },
      si: {
        translation: {
          "Opening hours": "Odpiralni čas",
          "Pick-up point not available": "Prevzemno mesto ni na voljo",
          "Pick-up point available": "Prevzemno mesto na voljo",
          Monday: "Ponedeljek",
          Tuesday: "Torek",
          Wednesday: "Sreda",
          Thursday: "Četrtek",
          Friday: "Petek",
          Saturday: "Sobota",
          Sunday: "Nedelja",
          "Wheelchair access": "Dostop za invalidske vozičke",
          Filters: "Filtri",
          Company: "Podjetje",
          "Deselect all": "Prekliči izbor vseh",
          "Select all": "Izberi vse",
          Apply: "Uporabi",
          "Cancel all": "Prekliči vse",
          "Possibility to pay by card": "Možnost plačila s kartico",
          "Not possible to pay by card at this pick-up point":
            "Na tem prevzemnem mestu ni mogoče plačati s kartico",
          "Open Saturday": "Odpri v soboto",
          "Open Sunday": "Odpri v nedeljo",
          "Back to point list": "Nazaj na seznam točk",
          "Pick up point selection": "Izberite prevzemno mesto",
          "Search for a pick up point": "Išči prevzemno mesto",
          "Choose this pick up point": "Izberite to prevzemno mesto",
          "Select an available pick-up point":
            "Izberite razpoložljivo prevzemno mesto",
          "There was an error! Please, try again later!":
            "Prišlo je do napake! Prosimo, poskusite znova pozneje!",
          "Load more results...": "Naloži več rezultatov...",
          "Please allow access to your location.":
            "Dovolite dostop do svoje lokacije.",
        },
      },
      it: {
        translation: {
          "Opening hours": "Orario di apertura",
          "Pick-up point not available": "Punto di ritiro non disponibile",
          "Pick-up point available": "Punto di ritiro disponibile",
          Monday: "Lunedì",
          Tuesday: "Martedì",
          Wednesday: "Mercoledì",
          Thursday: "Giovedì",
          Friday: "Venerdì",
          Saturday: "Sabato",
          Sunday: "Domenica",
          "Wheelchair access": "Accesso per sedie a rotelle",
          Filters: "Filtri",
          Company: "Azienda",
          "Deselect all": "Deseleziona tutto",
          "Select all": "Seleziona tutto",
          Apply: "Applica",
          "Cancel all": "Annulla tutto",
          "Possibility to pay by card": "Possibilità di pagare con carta",
          "Not possible to pay by card at this pick-up point":
            "Non è possibile pagare con carta in questo punto di ritiro",
          "Open Saturday": "Aperto il sabato",
          "Open Sunday": "Aperto la domenica",
          "Back to point list": "Torna alla lista dei punti",
          "Pick up point selection": "Selezione del punto di ritiro",
          "Search for a pick up point": "Cerca un punto di ritiro",
          "Choose this pick up point": "Scegli questo punto di ritiro",
          "Select an available pick-up point":
            "Seleziona un punto di ritiro disponibile",
          "There was an error! Please, try again later!":
            "Si è verificato un errore! Per favore, riprova più tardi!",
          "Load more results...": "Carica altri risultati...",
          "Please allow access to your location.":
            "Consenti l'accesso alla tua posizione.",
        },
      },
      hr: {
        translation: {
          "Opening hours": "Radno vrijeme",
          "Pick-up point not available": "Mjesto preuzimanja nije dostupno",
          "Pick-up point available": "Mjesto preuzimanja dostupno",
          Monday: "Ponedjeljak",
          Tuesday: "Utorak",
          Wednesday: "Srijeda",
          Thursday: "Četvrtak",
          Friday: "Petak",
          Saturday: "Subota",
          Sunday: "Nedjelja",
          "Wheelchair access": "Pristup za invalidska kolica",
          Filters: "Filteri",
          Company: "Tvrtka",
          "Deselect all": "Odznači sve",
          "Select all": "Odaberi sve",
          Apply: "Primijeni",
          "Cancel all": "Odustani od svega",
          "Possibility to pay by card": "Mogućnost plaćanja karticom",
          "Not possible to pay by card at this pick-up point":
            "Nije moguće platiti karticom na ovom mjestu preuzimanja",
          "Open Saturday": "Otvoreno subotom",
          "Open Sunday": "Otvoreno nedjeljom",
          "Back to point list": "Povratak na popis točaka",
          "Pick up point selection": "Odabir mjesta preuzimanja",
          "Search for a pick up point": "Pretražite mjesta preuzimanja",
          "Choose this pick up point": "Odaberite ovo mjesto preuzimanja",
          "Select an available pick-up point":
            "Odaberite dostupno mjesto preuzimanja",
          "There was an error! Please, try again later!":
            "Dogodila se greška! Molimo, pokušajte ponovo kasnije!",
          "Load more results...": "Učitaj više rezultata...",
          "Please allow access to your location.":
            "Dopustite pristup vašoj lokaciji.",
        },
      },
      pl: {
        translation: {
          "Opening hours": "Godziny otwarcia",
          "Pick-up point not available": "Punkt odbioru niedostępny",
          "Pick-up point available": "Punkt odbioru dostępny",
          Monday: "Poniedziałek",
          Tuesday: "Wtorek",
          Wednesday: "Środa",
          Thursday: "Czwartek",
          Friday: "Piątek",
          Saturday: "Sobota",
          Sunday: "Niedziela",
          "Wheelchair access": "Dostęp dla wózków inwalidzkich",
          Filters: "Filtry",
          Company: "Firma",
          "Deselect all": "Odznacz wszystko",
          "Select all": "Zaznacz wszystko",
          Apply: "Zastosuj",
          "Cancel all": "Anuluj wszystko",
          "Possibility to pay by card": "Możliwość płatności kartą",
          "Not possible to pay by card at this pick-up point":
            "Nie można płacić kartą w tym punkcie odbioru",
          "Open Saturday": "Otwarte w sobotę",
          "Open Sunday": "Otwarte w niedzielę",
          "Back to point list": "Powrót do listy punktów",
          "Pick up point selection": "Wybór punktu odbioru",
          "Search for a pick up point": "Wyszukaj punkt odbioru",
          "Choose this pick up point": "Wybierz ten punkt odbioru",
          "Select an available pick-up point": "Wybierz dostępny punkt odbioru",
          "There was an error! Please, try again later!":
            "Wystąpił błąd! Proszę spróbować ponownie później!",
          "Load more results...": "Załaduj więcej wyników...",
          "Please allow access to your location.":
            "Zezwól na dostęp do swojej lokalizacji.",
        },
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
