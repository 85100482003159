import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Map } from "./components/Map/Map";
import { State, ICollectPoint, Preferences } from "./types";
import {
  fetchPointsList,
  fetchPreferences,
  setCenter,
  setSelectedPoint,
} from "./store/actions";
import { ThunkDispatch } from "redux-thunk";
import { useEffect } from "react";
import { Header } from "./components/Header/Header";
import PointsList from "./components/Points/PointsList";
import { PointsSearch } from "./components/Points/PointsSearch";
import {
  createTheme,
  Snackbar,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";
import "./utils/fonts/fonts.css";
import "./App.css";
import "./components/Points/Points.css";
import { PointsFilters } from "./components/Points/PointsFilters";
import Box from "@mui/material/Box";
import { Alert } from "@mui/material";
import "./translations";
import { LatLng } from "leaflet";
import { Filters } from "./components/Filters/Filters";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// if (process.env.NODE_ENV === "development") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

const App: React.FC = () => {
  const { t } = useTranslation();
  const theme = createTheme(
    adaptV4Theme({
      props: {
        // Name of the component
        MuiButtonBase: {
          // The properties to apply
          disableRipple: true, // No more ripple, on the whole application!
        },
        MuiTypography: {
          fontFamily: "ApercuProRegular",
        },
      },
    })
  );
  const isMobile = document.body.clientWidth <= 1123;

  const selectedPoint: ICollectPoint | null = useSelector(
    (state: State) => state.selectedPoint,
    shallowEqual
  );

  const error: string | null = useSelector(
    (state: State) => state.apiError,
    shallowEqual
  );

  const initialPoints: ICollectPoint[] | null = useSelector(
    (state: State) => state.initialPointsList,
    shallowEqual
  );

  const availablePoints: ICollectPoint[] | null = useSelector(
    (state: State) => state.availablePoints,
    shallowEqual
  );

  const preferences: Preferences | null = useSelector(
    (state: State) => state.preferences,
    shallowEqual
  );

  const isFetching = useSelector(
    (state: State) => state.isFetching,
    shallowEqual
  );

  const permissionError = useSelector(
    (state: State) => state.permissionError,
    shallowEqual
  );

  const thunkDispatch: ThunkDispatch<any, any, any> = useDispatch();

  const recenterToUser = React.useCallback(
    (userLocation: LatLng) => thunkDispatch(setCenter(userLocation)),
    [thunkDispatch]
  );

  const userLocation = useSelector(
    (state: State) => state.userLocation,
    shallowEqual
  );

  const [mapVisible, setMapVisible] = React.useState(true);
  const [filtersVisible, setFiltersVisible] = React.useState(false);
  const [focusedSearch, setFocusedSearch] = React.useState(false);

  const searchParam = useSelector(
    (state: State) => state.filters.searchParam,
    shallowEqual
  );

  useEffect(() => {
    thunkDispatch(fetchPreferences());
    thunkDispatch(fetchPointsList());
  }, [thunkDispatch]);

  useEffect(() => {
    if (!permissionError) {
      recenterToUser(userLocation!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionError]);

  useEffect(() => {
    if (
      !selectedPoint &&
      availablePoints.length &&
      !searchParam?.length &&
      !mapVisible
    )
      thunkDispatch(setSelectedPoint(userLocation ? availablePoints[0] : null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, availablePoints]);

  useEffect(() => {
    if (!selectedPoint && !isMobile)
      thunkDispatch(setSelectedPoint(userLocation ? availablePoints[0] : null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePoints, isMobile]);

  useEffect(() => {
    if (selectedPoint) setFocusedSearch(false);
  }, [selectedPoint]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {error ? (
          <Snackbar open>
            <Alert severity="error">
              {t(error || "There was an error! Please, try again later!")}
            </Alert>
          </Snackbar>
        ) : null}
        <Box
          display="flex"
          flexDirection="column"
          height="100svh"
          maxHeight={"100svh"}
          width="100vw"
          maxWidth={"100vw"}
          className="appRoot"
          p={0}
          style={{ blockSize: "100svh" }}
          visibility={preferences ? "visible" : "hidden"}
        >
          <Box
            className={"header-sidebar"}
            display="flex"
            p={0}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Box
              className="header-box"
              display="flex"
              flexDirection="column"
              style={{
                width: isMobile ? "calc(100% - 20px)" : "500px",
                ...(!mapVisible
                  ? { flex: isMobile ? "calc(100% - 20px)" : "0 0 500px" }
                  : {}),
                display: "inherit",
                minWidth: isMobile ? "calc(100% - 20px)" : "500px",
                maxWidth: "100%",
              }}
              mx={1}
              mt={1}
            >
              {!isMobile && (
                <Box>
                  <Header />
                </Box>
              )}

              <Box display="flex">
                <PointsSearch
                  setFiltersVisible={setFiltersVisible}
                  setFocusedSearch={setFocusedSearch}
                  focusedSearch={focusedSearch}
                />
              </Box>
              <Box>
                <PointsFilters isMobile={isMobile} />
              </Box>
              {(!isMobile || (isMobile && !selectedPoint)) && (
                <PointsList
                  focusedSearch={focusedSearch}
                  points={availablePoints ?? initialPoints}
                  selectedPoint={selectedPoint}
                />
              )}
            </Box>
            <Box
              className={`filters-block ${filtersVisible ? "visible" : ""}`}
              display="flex"
              flexDirection="column"
              style={{
                width: isMobile ? "100%" : "500px",
                ...(!mapVisible
                  ? { flex: isMobile ? "100%" : "0 0 500px" }
                  : {}),
              }}
              mx={1}
              mt={1}
            >
              <Filters closeFilters={() => setFiltersVisible(false)} />
            </Box>
            <Box
              flexDirection="column"
              flexGrow={1}
              p={0}
              display={
                !isMobile || mapVisible || (isMobile && selectedPoint)
                  ? "flex"
                  : "none"
              }
            >
              <Map isMobile={isMobile} />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
