import * as React from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  Popup,
  useMapEvents,
} from "react-leaflet";
import { ICollectPoint, State } from "../../types";
import { LatLngTuple } from "leaflet";
import * as Leaflet from "leaflet";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setMapInstance,
  setSelectedPoint,
  setApiError,
  fetchPointsList,
} from "../../store/actions";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import PixiOverlay from "react-leaflet-pixi-overlay";
import CircularProgress from "@mui/material/CircularProgress";
import {
  brtSvg,
  cargusSvg,
  dpdSvg,
  econtSvg,
  fanCourierSvg,
  glsSvg,
  innoshipSvg,
  packeterySvg,
  pplSvg,
  sameDaySvg,
  selectedbrtSvg,
  selectedcargusSvg,
  selecteddpdSvg,
  selectedecontSvg,
  selectedfanCourierSvg,
  selectedglsSvg,
  selectedinnoshipSvg,
  selectedpacketerySvg,
  selectedpplSvg,
  selectedsameDaySvg,
  selectedspeedySvg,
  speedySvg,
} from "./markers";
import { computeDistance } from "../../utils/geo";
import { MapFooter } from "../MapFooter/MapFooter";

type Props = {
  isMobile?: boolean;
};

export const Map: React.FC<Props> = ({ isMobile = false }) => {
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const mapInstance: Leaflet.Map | null = useSelector(
    (state: State) => state.mapInstance,
    shallowEqual
  );
  const searchParam = useSelector(
    (state: State) => state.filters.searchParam,
    shallowEqual
  );
  const permissionError = useSelector(
    (state: State) => state.permissionError,
    shallowEqual
  );

  const selectedPoint: ICollectPoint | null = useSelector(
    (state: State) => state.selectedPoint,
    shallowEqual
  );

  const initialPointsList: any = useSelector(
    (state: State) => state.initialPointsList,
    shallowEqual
  );

  const [initialCenter] = useState<LatLngTuple>([45.792784, 24.152069]);
  const [initialZoom] = useState<number>(8);

  const [mapIsLoading, setMapIsLoading] = useState<boolean>(false);
  const userLocation = useSelector(
    (state: State) => state.userLocation,
    shallowEqual
  );

  useEffect(() => {
    if (userLocation && mapIsLoading) {
      mapInstance?.flyTo(userLocation, 17);
      setMapIsLoading(false);
    }

    if (permissionError && mapIsLoading) {
      mapInstance?.setView([52.519325, 13.392709], 4);
      setMapIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation, permissionError]);

  function LocationMarker() {
    const [position, setPosition] = useState<Leaflet.LatLngExpression | null>(
      null
    );
    const [zoomLevel, setZoomLevel] = useState(mapInstance?.getZoom()); // initial zoom level provided for MapContainer

    useEffect(() => {
      if (mapInstance) {
        mapInstance.invalidateSize();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPoint, mapInstance]);

    const getPoints = () => {
      return (
        !searchParam?.length &&
        dispatch(
          fetchPointsList({
            latitude: map?.getCenter().lat,
            longitude: map?.getCenter().lng,
            zoom: computeDistance(
              map?.getBounds().getNorthEast(),
              map?.getBounds().getSouthWest()
            ),
          })
        )
      );
    };

    const map = useMapEvents({
      zoomend(e) {
        // setTimeout() is here because there are issues with the library, we cannot detect if zoomend is programmatic or initiated by user
        // https://github.com/Leaflet/Leaflet/pull/6929 ...yay, library issues
        // I can go on a whoel rant here about redux.
        // fuck it, setTimeout() sends the code to the bottom of the execution stack
        // it works...
        setTimeout((e) => {
          getPoints();
          if ((zoomLevel || initialZoom) > map?.getZoom()) deselectPoint();
          setZoomLevel(map?.getZoom());
        }, 0);
      },
      dragend(e) {
        getPoints();
        map.invalidateSize(true);
        deselectPoint();
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>{t("You are here")}</Popup>
      </Marker>
    );
  }

  const deselectPoint = () => {
    if (isMobile) selectPoint(null);
  };

  const availablePoints = useSelector(
    (state: State) => state.availablePoints,
    shallowEqual
  );

  const selectPoint = React.useCallback(
    (selectedPoint: ICollectPoint | null) =>
      dispatch(setSelectedPoint(selectedPoint)),
    [dispatch]
  );
  useEffect(() => {
    let timer1 = setTimeout(() => {
      if (mapInstance) {
        mapInstance.invalidateSize(true);
      } else {
        dispatch(setApiError("There was an error! Please, try again later!"));
      }
      setMapIsLoading(false);
    }, 10000);
    return () => {
      clearTimeout(timer1);
    };
  }, [mapInstance, dispatch]);

  const getIcon = (connectorType: string, selected = false) => {
    let icon = "";

    if (connectorType === "SAMEDAY")
      icon = !selected ? selectedsameDaySvg : sameDaySvg;
    if (connectorType === "FAN_COURIER")
      icon = !selected ? selectedfanCourierSvg : fanCourierSvg;
    if (connectorType.includes("GLS"))
      icon = !selected ? selectedglsSvg : glsSvg;
    if (connectorType.includes("PPL"))
      icon = !selected ? selectedpplSvg : pplSvg;
    if (connectorType.includes("DPD"))
      icon = !selected ? selecteddpdSvg : dpdSvg;
    if (connectorType === "PACKETERY")
      icon = !selected ? selectedpacketerySvg : packeterySvg;
    if (connectorType === "URGENT_CARGUS")
      icon = !selected ? selectedcargusSvg : cargusSvg;
    if (connectorType === "ECONT")
      icon = !selected ? selectedecontSvg : econtSvg;
    if (connectorType === "INNOSHIP")
      icon = !selected ? selectedinnoshipSvg : innoshipSvg;
    if (connectorType.includes("BRT"))
      icon = !selected ? selectedbrtSvg : brtSvg;
    if (connectorType.includes("SPEEDY"))
      icon = !selected ? selectedspeedySvg : speedySvg;

    return icon;
  };

  return (
    <Box
      p={0}
      flexShrink={1}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      {selectedPoint ? (
        <Box
          flexGrow={1}
          flexBasis={250}
          style={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            right: 0,
            zIndex: 9999,
          }}
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mt={1}
        >
          <Box className="footer-class">
            <MapFooter selectedPoint={selectedPoint} />

            <Box
              display="flex"
              alignSelf="stretch"
              justifyContent="flex-start"
              pt={2}
            >
              {isMobile && (
                <Button
                  fullWidth={isMobile}
                  variant="outlined"
                  className="backButton"
                  onClick={() => {
                    selectPoint(null);
                  }}
                >
                  {t("Back")}
                </Button>
              )}
              <Button
                fullWidth={isMobile}
                variant="contained"
                className="choosePointButton"
                onClick={() => {
                  window.parent.postMessage(
                    JSON.stringify({
                      type: "result",
                      data: selectedPoint,
                    }),
                    "*"
                  );
                  window.parent.postMessage(
                    JSON.stringify({ type: "close", data: true }),
                    "*"
                  );
                }}
              >
                {t("Choose this pick up point")}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: mapIsLoading ? "flex" : "none",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
        <Typography variant="subtitle1" noWrap>
          {!permissionError
            ? t("Please allow access to your location.")
            : "Loading..."}
        </Typography>
      </Box>
      <Box
        sx={{
          display: mapIsLoading ? "hidden" : "initial",
        }}
      >
        <MapContainer
          id="map"
          className="map hidden-map"
          style={{
            height: "100%",
          }}
          center={initialCenter}
          preferCanvas
          zoom={initialZoom}
          whenCreated={(map) => {
            if (!mapInstance) {
              map.locate();
              setMapIsLoading(true);

              dispatch(setMapInstance(map));
            }
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://about.xconnector.app/" target="_blank">xConnector</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <PixiOverlay
            markers={React.useMemo(
              () =>
                initialPointsList.map(
                  (collectPoint: ICollectPoint, idx: any) => ({
                    id: idx,
                    iconId: "icon" + collectPoint.id + Math.random(),
                    customIcon: getIcon(
                      collectPoint.connectorType,
                      selectedPoint?.id === collectPoint.id
                    ),
                    onClick: () => {
                      if (
                        !searchParam &&
                        availablePoints.length &&
                        document.querySelector("#available-points")
                      ) {
                        document.querySelector(
                          "#available-points"
                        )!.scrollTop = 0;
                      }
                      selectPoint(collectPoint);
                    },
                    position: [
                      Number(collectPoint.latitude),
                      Number(collectPoint.longitude),
                    ],
                  })
                ),
              [selectedPoint, initialPointsList]
            )}
          />

          <LocationMarker />
        </MapContainer>
      </Box>
    </Box>
  );
};
