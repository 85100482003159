interface Props {
  className?: string;
}

export const CheckboxEmpty: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" stroke="#C7C7C8" />
    </svg>
  );
};
