interface Props {
  onClick: () => void;
}

export const CloseIcon: React.FC<Props> = (props) => {
  return (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.95637 1.32276L4.77916 4.49997L7.95637 7.67717L7.15539 8.47815L3.97818 5.30094L0.800977 8.47815L0 7.67717L3.17721 4.49997L0 1.32276L0.800977 0.521782L3.97818 3.69899L7.15539 0.521782L7.95637 1.32276Z"
        fill="#010101"
      />
    </svg>
  );
};
