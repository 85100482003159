import * as React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

type Props = {
  dayLabel: string;
  scheduleValue: string | null;
};

export const ScheduleItem: React.FC<Props> = ({ dayLabel, scheduleValue }) => {
  return (
    <React.Fragment>
      {scheduleValue ? (
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">
            <strong>{dayLabel}</strong>
          </Typography>
          <Typography variant="body2">{scheduleValue}</Typography>
        </Box>
      ) : null}
    </React.Fragment>
  );
};
