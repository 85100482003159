import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { shallowEqual, useSelector } from "react-redux";
import { Preferences, State } from "../../types";
import "./Header.css";

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const preferences: Preferences | null = useSelector(
    (state: State) => state.preferences,
    shallowEqual
  );

  return (
    <header>
      <Box
        display="flex"
        mx={1}
        justifyContent="space-between"
        className={"header"}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h6" noWrap className="header-title">
            {t("Pick up point selection")}
          </Typography>
        </Box>
        <Box flexShrink={1} display="flex" justifyContent="space-between">
          <Box alignSelf="center" width="100%" style={{ textAlign: "center" }}>
            <img
              src={preferences ? preferences.logo : "./logo.png"}
              alt="Logo"
              height="35px"
            />
          </Box>
        </Box>
      </Box>
    </header>
  );
};
