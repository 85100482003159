import React from "react";

import { useTranslation } from "react-i18next";
import { Filters, State } from "../../types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setFilters, fetchPointsList } from "../../store/actions";
import { Box, Typography } from "@mui/material";
import { computeDistance } from "../../utils/geo";
import { CloseIcon } from "../Icons/CloseIcon";

export const PointsFilters = ({ isMobile = false }) => {
  const { t } = useTranslation();

  const [localFilters, setLocalFilters] = React.useState<Filters>({
    saturdayOpen: false,
    sundayOpen: false,
    wheelChairAccessible: false,
    availableCapacity: false,
    payByCard: false,
    searchParam: "",
    connectorTypes: [],
  });

  const storeFilters: Filters = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const mapInstance = useSelector(
    (state: State) => state.mapInstance,
    shallowEqual
  );

  React.useEffect(() => {
    setLocalFilters({ ...storeFilters });
  }, [storeFilters]);

  const dispatch: React.Dispatch<any> = useDispatch();

  const setFiltersAction = React.useCallback(
    (filters: Filters) => dispatch(setFilters(filters)),
    [dispatch]
  );

  const closeFilter = (field: string, connector = false) => {
    let newFilters = localFilters;
    if (connector) {
      newFilters = {
        ...localFilters,
        connectorTypes: localFilters.connectorTypes.filter(
          (item) => item !== field
        ),
      };
    } else {
      newFilters = { ...localFilters, [field]: false };
    }
    setLocalFilters(newFilters);

    const filters = {
      ...newFilters,
      searchParam: storeFilters.searchParam,
    } as Filters;

    dispatch(setFiltersAction(filters));
    dispatch(
      fetchPointsList({
        zoom: computeDistance(
          mapInstance?.getBounds().getNorthEast()!,
          mapInstance?.getBounds().getSouthWest()!
        ),
        latitude: mapInstance?.getCenter().lat,
        longitude: mapInstance?.getCenter().lng,
      })
    );

    if (document.querySelector("#available-points")) {
      document.querySelector("#available-points")!.scrollTop = 0;
    }
  };
  const getIcon = (connectorType: string) => {
    let logoTitle = "";

    if (connectorType) {
      if (connectorType === "SAMEDAY") logoTitle = "Sameday";
      if (connectorType === "FAN_COURIER") logoTitle = "FanCourier";
      if (connectorType.includes("GLS")) logoTitle = "GLS";
      if (connectorType.includes("PPL")) logoTitle = "PPL";
      if (connectorType.includes("DPD")) logoTitle = "DPD";
      if (connectorType === "PACKETERY") logoTitle = "Packeta";
      if (connectorType === "URGENT_CARGUS") logoTitle = "Cargus";
      if (connectorType === "ECONT") logoTitle = "Econt";
      if (connectorType === "INNOSHIP") logoTitle = "Innoship";
      if (connectorType.includes("BRT")) logoTitle = "BRT";
      if (connectorType.includes("SPEEDY")) logoTitle = "Speedy";
    }

    return logoTitle;
  };
  return (
    <Box
      style={{
        width: "100%",
        gap: "0 10px",
        flexWrap: "wrap",
        display: "flex",
      }}
    >
      {localFilters?.saturdayOpen && (
        <Box className="filter-item">
          <Typography>{t("Open Saturday")}</Typography>
          <CloseIcon onClick={() => closeFilter("saturdayOpen")} />
        </Box>
      )}
      {localFilters?.sundayOpen && (
        <Box className="filter-item">
          <Typography>{t("Open Sunday")}</Typography>
          <CloseIcon onClick={() => closeFilter("sundayOpen")} />
        </Box>
      )}
      {localFilters?.payByCard && (
        <Box className="filter-item">
          <Typography>{t("Possibility to pay by card")}</Typography>
          <CloseIcon onClick={() => closeFilter("payByCard")} />
        </Box>
      )}
      {localFilters?.wheelChairAccessible && (
        <Box className="filter-item">
          <Typography>{t("Wheelchair access")}</Typography>
          <CloseIcon onClick={() => closeFilter("wheelChairAccessible")} />
        </Box>
      )}
      {localFilters?.availableCapacity && (
        <Box className="filter-item">
          <Typography>{t("Pick-up point available")}</Typography>
          <CloseIcon onClick={() => closeFilter("availableCapacity")} />
        </Box>
      )}
      {localFilters?.connectorTypes?.map((item) => (
        <Box className="filter-item">
          <Typography>{getIcon(item)}</Typography>
          <CloseIcon onClick={() => closeFilter(item, true)} />
        </Box>
      ))}
    </Box>
  );
};
