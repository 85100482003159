import * as React from "react";
import { useTranslation } from "react-i18next";
import { Filters, ICollectPoint, Preferences, State } from "../../types";
import { setFilters, setSelectedPoint } from "../../store/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { Box, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { applyFilters } from "../../store/actions";
import { ThunkDispatch } from "redux-thunk";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { SearchIcon } from "../Icons/SearchIcon";
import { CloseIcon } from "../Icons/CloseIcon";

interface Props {
  setFiltersVisible: (e: boolean) => void;
  setFocusedSearch: (e: boolean) => void;
  focusedSearch: boolean;
}

export const PointsSearch: React.FC<Props> = ({
  setFiltersVisible,
  setFocusedSearch,
  focusedSearch,
}: Props) => {
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const thunkDispatch: ThunkDispatch<any, any, any> = useDispatch();
  const [count, setCount] = React.useState(0);
  const [searchParams, setSearchParams] = React.useState("");

  const selectPoint = React.useCallback(
    (selectedPoint: ICollectPoint | null) =>
      dispatch(setSelectedPoint(selectedPoint)),
    [dispatch]
  );
  const storeFilters: Filters = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const preferences: Preferences | null = useSelector(
    (state: State) => state.preferences,
    shallowEqual
  );
  const isMobile = document.body.clientWidth <= 1123;
  React.useEffect(() => {
    let counter = 0;

    if (storeFilters.wheelChairAccessible) counter++;
    if (storeFilters.payByCard) counter++;
    if (storeFilters.availableCapacity) counter++;

    setCount(counter + storeFilters.connectorTypes.length);
  }, [storeFilters]);

  const [typingTimeout, setTypingTimeout] =
    React.useState<NodeJS.Timeout | null>(null);

  const updateSearch = (value: string) => {
    if (typingTimeout) clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(() => {
      const searchParam = value === "" ? null : value;
      dispatch(
        setFilters({
          ...storeFilters,
          searchParam: searchParam,
        })
      );
      thunkDispatch(applyFilters());
    }, 1000);
    setTypingTimeout(newTypingTimeout);
  };

  return (
    <Box className="search-wrapper">
      <TextField
        className={`input-field ${focusedSearch ? "focused" : ""}`}
        placeholder={t("Search for a pick up point")}
        focused={false}
        value={searchParams}
        variant="outlined"
        onFocus={() => {
          selectPoint(null);
          searchParams !== "" && setFocusedSearch(true);
        }}
        onChange={(e) => {
          e.target.value !== ""
            ? setFocusedSearch(true)
            : setFocusedSearch(false);
          updateSearch(e.target.value);
          setSearchParams(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end" style={{ marginLeft: 0 }}>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment:
            searchParams !== "" ? (
              <InputAdornment
                position="end"
                style={{ marginRight: "10px", cursor: "pointer" }}
              >
                <CloseIcon
                  onClick={() => {
                    updateSearch("");
                    setSearchParams("");
                    setFocusedSearch(false);
                  }}
                />
              </InputAdornment>
            ) : null,
        }}
      />

      <Box className="filters-box" onClick={() => setFiltersVisible(true)}>
        {!isMobile && <Typography>{t("Filters")}</Typography>}
        {count > 0 && <Box className="filters-count">{count}</Box>}{" "}
        <TuneOutlinedIcon />
      </Box>
      {!focusedSearch && isMobile && (
        <img
          src={preferences ? preferences.logo : "./logo.png"}
          alt="Logo"
          height="35px"
        />
      )}
    </Box>
  );
};
