interface Props {
  className?: string;
  style?: any;
}

export const CardIcon: React.FC<Props> = ({ className, style }) => {
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 15.625H17.625C18.1428 15.625 18.5625 15.2053 18.5625 14.6875C18.5625 14.1697 18.1428 13.75 17.625 13.75H15.75C15.2322 13.75 14.8125 14.1697 14.8125 14.6875C14.8125 15.2053 15.2322 15.625 15.75 15.625Z"
        fill="black"
      />
      <path
        d="M21.1875 0.625H2.8125C1.26169 0.625 0 1.88669 0 3.4375V16.5625C0 18.1133 1.26169 19.375 2.8125 19.375H21.1875C22.7383 19.375 24 18.1133 24 16.5625V10.9375C24 10.4197 23.5803 10 23.0625 10C22.5447 10 22.125 10.4197 22.125 10.9375V16.5625C22.125 17.0794 21.7044 17.5 21.1875 17.5H2.8125C2.29556 17.5 1.875 17.0794 1.875 16.5625V8.125H23.0625C23.5803 8.125 24 7.70528 24 7.1875V3.4375C24 1.88669 22.7383 0.625 21.1875 0.625ZM1.875 3.4375C1.875 2.92056 2.29556 2.5 2.8125 2.5H21.1875C21.7044 2.5 22.125 2.92056 22.125 3.4375V6.25H1.875V3.4375Z"
        fill="black"
      />
      <path
        d="M4.6875 13.75C4.16972 13.75 3.75 14.1697 3.75 14.6875C3.75 15.2053 4.16972 15.625 4.6875 15.625H6.5625C7.08028 15.625 7.5 15.2053 7.5 14.6875C7.5 14.1697 7.08028 13.75 6.5625 13.75H4.6875Z"
        fill="black"
      />
      <path
        d="M10.125 13.75C9.60722 13.75 9.1875 14.1697 9.1875 14.6875C9.1875 15.2053 9.60722 15.625 10.125 15.625H12C12.5178 15.625 12.9375 15.2053 12.9375 14.6875C12.9375 14.1697 12.5178 13.75 12 13.75H10.125Z"
        fill="black"
      />
    </svg>
  );
};
