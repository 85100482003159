import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  Switch,
  SwitchProps,
  Typography,
  styled,
} from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Filters as FiltersType, Preferences, State } from "../../types";
import "./Filters.css";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  brtLogo,
  cargusLogo,
  dpdLogo,
  econtLogo,
  fanCourierLogo,
  glsLogo,
  innoshipLogo,
  packeteryLogo,
  pplLogo,
  sameDayLogo,
  speedyLogo,
} from "../Map/markers";
import { CheckboxEmpty } from "../Checkbox/CheckboxEmpty";
import { CheckboxIcon } from "../Checkbox/Checkbox";
import { fetchPointsList, setFilters } from "../../store/actions";
import { computeDistance } from "../../utils/geo";

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 56,
  height: 30,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#000000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#000000",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 24,
  },
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface Props {
  closeFilters: () => void;
}
export const Filters: React.FC<Props> = ({ closeFilters }) => {
  const { t } = useTranslation();
  const dispatch: React.Dispatch<any> = useDispatch();
  const [openedCompanies, setOpenedCompanies] = React.useState(false);
  const [localFilters, setLocalFilters] = React.useState<FiltersType>({
    saturdayOpen: false,
    sundayOpen: false,
    wheelChairAccessible: false,
    availableCapacity: false,
    payByCard: false,
    searchParam: "",
    connectorTypes: [],
  });

  const preferences: Preferences | null = useSelector(
    (state: State) => state.preferences,
    shallowEqual
  );

  const mapInstance = useSelector(
    (state: State) => state.mapInstance,
    shallowEqual
  );

  const storeFilters: FiltersType = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );

  React.useEffect(() => {
    setLocalFilters({ ...localFilters, ...storeFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFilters]);

  const getIcon = (connectorType: string) => {
    let logo = { icon: "", title: "" };

    if (connectorType) {
      if (connectorType === "SAMEDAY")
        logo = { icon: sameDayLogo, title: "Sameday" };
      if (connectorType === "FAN_COURIER")
        logo = { icon: fanCourierLogo, title: "FAN" };
      if (connectorType.includes("GLS")) logo = { icon: glsLogo, title: "GLS" };
      if (connectorType.includes("PPL")) logo = { icon: pplLogo, title: "PPL" };
      if (connectorType.includes("DPD")) logo = { icon: dpdLogo, title: "DPD" };
      if (connectorType === "PACKETERY")
        logo = { icon: packeteryLogo, title: "PACKETA" };
      if (connectorType === "URGENT_CARGUS")
        logo = { icon: cargusLogo, title: "Cargus" };
      if (connectorType === "ECONT") logo = { icon: econtLogo, title: "Econt" };
      if (connectorType === "INNOSHIP")
        logo = { icon: innoshipLogo, title: "Innoship" };
      if (connectorType.includes("BRT")) logo = { icon: brtLogo, title: "BRT" };
      if (connectorType.includes("SPEEDY"))
        logo = { icon: speedyLogo, title: "Speedy" };
    }

    return logo;
  };

  const changeFilters = (clear = false) => {
    let filters;
    if (clear) {
      filters = {
        saturdayOpen: false,
        sundayOpen: false,
        wheelChairAccessible: false,
        availableCapacity: false,
        payByCard: false,
        searchParam: "",
        connectorTypes: [],
      };
      setLocalFilters(filters);
      closeFilters();
    } else {
      filters = {
        ...localFilters,
        searchParam: storeFilters.searchParam,
      } as FiltersType;
    }
    dispatch(setFiltersAction(filters));
    dispatch(
      fetchPointsList({
        zoom: computeDistance(
          mapInstance?.getBounds().getNorthEast()!,
          mapInstance?.getBounds().getSouthWest()!
        ),
        latitude: mapInstance?.getCenter().lat,
        longitude: mapInstance?.getCenter().lng,
      })
    );
    closeFilters();

    if (document.querySelector("#available-points")) {
      document.querySelector("#available-points")!.scrollTop = 0;
    }
  };

  const setFiltersAction = React.useCallback(
    (filters: FiltersType) => dispatch(setFilters(filters)),
    [dispatch]
  );

  const updateLocalFilters = (connectorType: string) => {
    let checkedList = [];
    if (localFilters.connectorTypes.includes(connectorType)) {
      checkedList = localFilters.connectorTypes.filter(
        (type) => type !== connectorType
      );
    } else {
      checkedList = [...localFilters.connectorTypes, connectorType];
    }
    setLocalFilters({
      ...localFilters,
      connectorTypes: checkedList,
    });
  };

  return (
    <>
      <Box display="flex" alignItems="center" className={"filters-header"}>
        <TuneOutlinedIcon fontSize={"large"} className="filters-icon" />

        <Typography variant="h6" noWrap className="div-title">
          {t("Filters")}
        </Typography>

        <CloseIcon
          fontSize="medium"
          className="close-btn"
          onClick={() => {
            closeFilters();
            setLocalFilters(storeFilters);
          }}
        />
      </Box>
      <Box className="filters-wrapper">
        <Box className="filters-list">
          <Box className="filters-item">
            <Box className="filter-item__header">
              <Typography style={{ display: "flex", gap: 10 }}>
                {t("Company")}
                {localFilters.connectorTypes.length > 0 && (
                  <Typography style={{ color: "gray" }}>
                    ({localFilters.connectorTypes.length})
                  </Typography>
                )}
              </Typography>
              {localFilters.connectorTypes.length ===
              preferences?.showCompanies.length ? (
                <Typography
                  className="select-all"
                  onClick={() => {
                    setLocalFilters({
                      ...localFilters,
                      connectorTypes: [],
                    });
                  }}
                >
                  {t("Deselect all")}
                </Typography>
              ) : (
                <Typography
                  className="select-all"
                  onClick={() => {
                    setLocalFilters({
                      ...localFilters,
                      connectorTypes: preferences?.showCompanies ?? [],
                    });
                  }}
                >
                  {t("Select all")}
                </Typography>
              )}
              {openedCompanies ? (
                <ExpandLessIcon
                  className={"arrow-icon"}
                  fontSize="medium"
                  onClick={() => setOpenedCompanies(!openedCompanies)}
                />
              ) : (
                <ExpandMoreIcon
                  className={"arrow-icon"}
                  fontSize="medium"
                  onClick={() => setOpenedCompanies(!openedCompanies)}
                />
              )}
            </Box>
            {openedCompanies && (
              <Box className="companies-list">
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  width="50%"
                  gap={"13px"}
                >
                  {preferences?.showCompanies.slice(0, 5).map((company) => (
                    <Box className="companies-item">
                      <Checkbox
                        checked={localFilters.connectorTypes.includes(company)}
                        onClick={() => updateLocalFilters(company)}
                        checkedIcon={<CheckboxIcon className="checkbox-icon" />}
                        icon={<CheckboxEmpty className="checkbox-icon" />}
                        className="checkbox"
                        size="medium"
                      />
                      <div
                        className="mobile-icon"
                        dangerouslySetInnerHTML={{
                          __html: getIcon(company).icon,
                        }}
                      />
                      <Typography>{getIcon(company).title}</Typography>
                    </Box>
                  ))}
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  width="max-content"
                  gap={"13px"}
                  pr={"24px"}
                >
                  {preferences?.showCompanies.slice(5, 10).map((company) => (
                    <Box className="companies-item">
                      <Checkbox
                        checked={localFilters.connectorTypes.includes(company)}
                        onClick={() => updateLocalFilters(company)}
                        checkedIcon={<CheckboxIcon className="checkbox-icon" />}
                        icon={<CheckboxEmpty className="checkbox-icon" />}
                        className="checkbox"
                        size="medium"
                      />
                      <div
                        className="mobile-icon"
                        dangerouslySetInnerHTML={{
                          __html: getIcon(company).icon,
                        }}
                      />
                      <Typography>{getIcon(company).title}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          {preferences?.showWheelChairAccessibleFilter && (
            <Box className="filters-item">
              <Box className="filter-item__header">
                <Typography>{t("Wheelchair access")}</Typography>
                <CustomSwitch
                  onChange={(e) => {
                    setLocalFilters({
                      ...localFilters,
                      wheelChairAccessible: e.target.checked,
                    });
                  }}
                  checked={localFilters?.wheelChairAccessible}
                />
              </Box>
            </Box>
          )}
          {preferences?.showPayByCardFilter && (
            <Box className="filters-item">
              <Box className="filter-item__header">
                <Typography>{t("Possibility to pay by card")}</Typography>
                <CustomSwitch
                  onChange={(e) => {
                    setLocalFilters({
                      ...localFilters,
                      payByCard: e.target.checked,
                    });
                  }}
                  checked={localFilters?.payByCard}
                />
              </Box>
            </Box>
          )}
          {preferences?.showAvailableCapacityFilter && (
            <Box className="filters-item">
              <Box className="filter-item__header">
                <Typography>{t("Pick-up point available")}</Typography>
                <CustomSwitch
                  onChange={(e) => {
                    setLocalFilters({
                      ...localFilters,
                      availableCapacity: e.target.checked,
                    });
                  }}
                  checked={localFilters?.availableCapacity}
                />
              </Box>
            </Box>
          )}

          {preferences?.showSaturdayOpenFilter && (
            <Box className="filters-item">
              <Box className="filter-item__header">
                <Typography>{t("Saturday open")}</Typography>
                <CustomSwitch
                  onChange={(e) => {
                    setLocalFilters({
                      ...localFilters,
                      saturdayOpen: e.target.checked,
                    });
                  }}
                  checked={localFilters?.saturdayOpen}
                />
              </Box>
            </Box>
          )}
          {preferences?.showSundayOpenFilter && (
            <Box className="filters-item">
              <Box className="filter-item__header">
                <Typography>{t("Sunday open")}</Typography>
                <CustomSwitch
                  onChange={(e) => {
                    setLocalFilters({
                      ...localFilters,
                      sundayOpen: e.target.checked,
                    });
                  }}
                  checked={localFilters?.sundayOpen}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box className="btns-wrapper">
          <Button
            variant="contained"
            className="applyButton"
            onClick={() => changeFilters()}
          >
            {t("Apply")}
          </Button>
          <Button
            variant="outlined"
            className="backButton"
            onClick={() => changeFilters(true)}
          >
            {t("Cancel all")}
          </Button>
        </Box>
      </Box>
    </>
  );
};
