export const getIFrameParamValue = (paramName: string) => {
    const url = window.location.search.substring(1); //get rid of "?" in querystring
    const qArray = url.split("&"); //get key-value pairs
    for (let i = 0; i < qArray.length + 1; i++) {
        if (typeof qArray[i] !== 'undefined') {
            const pArr = qArray[i].split("="); //split key and value
            if (pArr[0] === paramName) return pArr[1];
        }
    }
    return "";
};