export const SearchIcon: React.FC = (props) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_37_2174)">
        <path
          d="M9.06777 18.1189C4.07235 18.1189 0.00830078 14.0549 0.00830078 9.05947C0.00830078 4.06405 4.07235 0 9.06777 0C14.0632 0 18.1272 4.06405 18.1272 9.05947C18.1272 14.0549 14.0632 18.1189 9.06777 18.1189ZM9.06777 1.71744C5.01935 1.71744 1.72574 5.01105 1.72574 9.05947C1.72574 13.1079 5.01935 16.4015 9.06777 16.4015C13.1162 16.4015 16.4098 13.1079 16.4098 9.05947C16.4098 5.01105 13.1162 1.71744 9.06777 1.71744ZM21.74 21.7485C22.0753 21.4131 22.0753 20.8694 21.74 20.534L17.8972 16.6913C17.5619 16.3559 17.0181 16.3559 16.6828 16.6913C16.3475 17.0266 16.3475 17.5704 16.6828 17.9057L20.5256 21.7485C20.6932 21.9161 20.913 22 21.1328 22C21.3526 22 21.5723 21.9161 21.74 21.7485Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_2174">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
